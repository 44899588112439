
import { useStore } from '@/store'
import { defineComponent } from 'vue'
import {
  County,
  CountyCommonality,
  CountyCommonalityCode,
  EcoArea,
  FgcaCommonality,
  Plant,
} from '@/@types/plants'

export default defineComponent({
  name: 'Plant',
  data() {
    return {
      store: useStore(),
      plant: undefined as undefined | Plant,
    }
  },
  beforeMount() {
    const { genus, species } = this.$route.params
    // Find the requested plant.
    this.plant = this.store.state.plantDatabase.plants.find(
      (plant) => plant.genus === genus && plant.species == species,
    )
    if (!this.plant) {
      this.$router.push({ name: '/' })
    }
  },
  computed: {
    // hero() {
    //   if (this.plant?.media.hero) {
    //     const hero = this.plant?.media.hero
    //     return require(`../assets/plantHeroes/${hero}`)
    //   }
    //   return undefined
    // },
  },
  methods: {
    decodeFgcaCommonalities(commonalities: FgcaCommonality[]): string {
      const description = commonalities
        .flatMap((fgcaCommonality) => {
          return `${this.decodeFgcaEcoArea(fgcaCommonality.ecoRegion)}, ${this.decodeFgcaEcoArea(
            fgcaCommonality.ecoDistrict,
          )}`
        })
        .join(', ')
      return `${description.charAt(0).toUpperCase()}${description.slice(1)}.`
    },
    decodeFgcaEcoArea(area: EcoArea): string {
      if (typeof area.commonality === 'string') {
        const code = this.expandCommonalityCode(area.commonality)
        return `${code} in ${area.code}`
      }

      const countyCommonality = area.commonality as CountyCommonality
      const counties = Object.keys(countyCommonality).map((key) => {
        const code = this.expandCommonalityCode(key)
        return `${code} in ${this.joinCounties(countyCommonality[key as CountyCommonalityCode])}`
      })
      return `${counties.join(', ')} of district ${area.code}`
    },
    expandCommonalityCode(commonality: string) {
      switch (commonality) {
        case 'C':
          return 'common'
        case 'U':
          return 'uncommon'
        case 'R':
          return 'rare'
        case 'X':
          return 'except'
      }
      return '???'
    },
    joinCounties(counties: County[]) {
      if (counties.length === 1) {
        return `county ${counties[0]}`
      }
      return `counties ${counties.slice(0, -1).join(', ')} and ${counties.slice(-1)}`
    },
  },
})
